import portfolioPic from "./images/aviportfoliowebsiteProjectPic.png"
import bpaPic from "./images/bpaProjectPic.png"
import blackjackPic from "./images/blackjackProjectPic.png"
import hsmnPic from "./images/hsmnProjectPic.png"
import trojanroboticsPic from "./images/trojanroboticsProjectPic.png"
import financialyearlyreportsPic from "./images/financialyearlyreportsProjectPic.png"
import youthVoterProjectPic from "./images/youthVoterProjectPic.png"
import paulKimblerPic from "./images/paulKimblerProjectPic.png"
import decaLetteringPic from "./images/decaLetteringProjectPic.png"
import comingSoonPic from "./images/comingSoonProjectPic.png"
import counterzPic from "./images/counterzProjectPic.png"
import shecanPic from "./images/shecanProjectPic.png"
import metaDataReportsSenderPic from "./images/metaDataReportsSender.jpeg"
import merchStorePic from "./images/merchStoreProjectPic.jpg"
import ashraCorpPic from "./images/ashraCorpProjectPic.jpeg"
import vidyaProjectPic from "./images/vidyaProjectPic.jpg"


// People Pics
import braydenPic from "./images/brayden.jpg"
import markusPic from "./images/markus.jpg"
import prohittPic from "./images/prohittImg.jpg"
import rahulPic from "./images/rahul.jpeg"
import aviPic from "./images/avi.jpeg"
import sarvajithPic from "./images/sarvajith.jpeg"

// Tee Pics
import teePicOne from "./images/teePicOne.jpg"
import teePicTwo from "./images/teePicTwo.jpg"
import teePicThree from "./images/teePicThree.jpg"


// Hoodie Pics
import hoodiePicOne from "./images/hoodiePicOne.jpg"
import hoodiePicTwo from "./images/hoodiePicTwo.jpg"
import hoodiePicThree from "./images/hoodiePicThree.jpg"

// Social links which are on here and the footer
const linkedinLink = "https://www.linkedin.com/in/avi-bandi-397279227/"
const githubLink = "https://github.com/aviBandi"
const instagramLink = "https://www.instagram.com/avibandi_designs/"


// Testimonial information
const testimonialInfo = [
    {
        personName:"Markus Wessman",
        personTitle: "Director of TYVP",
        imgSrc: markusPic,
        // testimonial:"The communication during the development was flawless and Avi did an amazing job, exceeding all of our expectations; his punctuality, meticulousness, and conscientiousness are unparalleled. "
        testimonial:"Avi is a very talented developer who is able to take a project from start to finish with minimal oversight. He is a great communicator and is able to take feedback excellently. I would highly recommend Avi for any project."
    },
    {
        personName:"Brayden Wright",
        personTitle: "Web Developer",
        imgSrc: braydenPic,
        testimonial:"I've worked with Avi, and he is a very fast learner when something new is thrown at him. He is dedicated to getting the task done, and works very well with people. I would highly recommend any of Avi's amazing services."
    },
  

]

// Project info which is on the Portfolio pages and the first three on home page
const projectInfo = [
    {
        title: "Vidya.Ai",
        desc: "Learning Sanskrit through Responsive AI Feedback. Launching in a few months.",
        skills: ["No Stat", "No Stat", "No Stat", "No Stat", "No Stat", "No Stat"],

        imgSrc: vidyaProjectPic,
        websiteLink: "https://www.vidyaai.app/",
        codeLink: ""
    },
    {
        title: "Ashra Corporation",
        desc: "A non profit that provides support and resources to under-resourced families across South Asia.",
        skills: ["Next.Js", "Framer Motion", "Typescript", "CSS", "Next UI", "Javascript", "NPM", "Vercel"],

        imgSrc: ashraCorpPic,
        websiteLink: "https://www.ashracorp.org/",
        codeLink: "https://github.com/aviBandi/ASHRA-Website"
    },
    {

        title: "HSMN Website",
        desc: "Website for a multimillion dollar Hindu nonprofit based in Minnesota.",
        skills: ["Search Engine Optimization", "Teamwork", "Timley Delivery", "HTML", "UI/UX Design"],
        imgSrc: hsmnPic,
        websiteLink: "https://www.hindutemplemn.org/",
        codeLink: ""
    },
    {
        title: "Counterz",
        desc: "Desktop application that analyzes and reports traffic data analytics.",
        skills: ["Python", "OpenCV", "Machine Learning", "Convolutional Neural Networks", "Kivy"],
        imgSrc: counterzPic,
        websiteLink: "https://github.com/aviBandi/Counterz-Desktop-Application",
        codeLink: "https://github.com/aviBandi/Counterz-Desktop-Application"
    },
    {

        title: "Youth Voter Project Website",
        desc: "Nonprofit to connect teens to democracy accross Minnesota.",
        skills: ["React.js", "TailwindCSS", "HTML", "NPM", "Figma"],
        imgSrc: youthVoterProjectPic,
        websiteLink: "https://www.youthvoterproject.org/",
        codeLink: "https://github.com/aviBandi/youthVoterProject"
    },
    {
        title: "Trojan Robotics Website",
        desc: "Website for my high school robotics team... also my first website I ever built!",
        skills: ["React.js", "TailwindCSS", "CSS", "HTML", "Vercel", "NPM"],
        imgSrc: trojanroboticsPic,
        websiteLink: "https://www.trojanrobotics.com/home",
        codeLink: "https://github.com/Team-2264/RoboticsWebsite"
    },
    {

        title: "Personal Merch Store",
        desc: "Merch store with latest trends for my personal brand!",
        skills: [ "Stripe API",  "Server Deployment","Javascript", "React.js", "TailwindCSS", "Ecommerce",],
        imgSrc: merchStorePic,
        websiteLink: "https://www.avibandi.com/merch",
        codeLink: ""
    },
    {

        title: "Financial Yearly Reports",
        desc: "Desktop application that sends financial statements on IRS regulations for 501(c)(3) nonprofits.",
        skills: ["Python", "MongoDB", "PyGUI", "Google API's", "Miro", "QuickBooks Integration"],
        imgSrc: financialyearlyreportsPic,
        websiteLink: "",
        codeLink: ""
    },
    {

        title: "My Portfolio Website!",
        desc: "Can't make all those websites without one for myself! Check it out.",
        skills: ["TailwindCSS", "React.js", "Figma", "Vercel", "HTML", "NPM"],
        imgSrc: portfolioPic,
        websiteLink: "https://www.avibandi.com/",
        codeLink: "https://github.com/aviBandi/aviPortfolioWebsite"
    },
    {

        title: "Sheets Records Keeper",
        desc: "Transitioned google sheets crosscheck from forty minute process to one minute automated process.",
        skills: ["Python", "Google Sheets API", "Problem Solving", "Tkinter (in process of creating GUI)"],
        imgSrc: bpaPic,
        websiteLink: "https://github.com/aviBandi/BPA-Automation",
        codeLink: "https://github.com/aviBandi/BPA-Automation"
    },
    {

        title: "Paul Kimbler's Website",
        desc: "Website for a Wayzata High School Teacher and DECA Advisor.",
        skills: ["React.js", "HTML", "TailwindCSS", "Figma", "Vercel", "NPM"],
        imgSrc: paulKimblerPic,
        websiteLink: "https://www.paulkimbler.com/",
        codeLink: "https://github.com/aviBandi/Paul-Kimbler-Website"
    },

    {
        title: "Lettering Automation",
        desc: "Automatic PDF generator for Wayzata DECA lettering.",
        skills: ["Python", "PyPDF2", "ReportLab", "Problem Solving","PDF Generation"],
        imgSrc: decaLetteringPic,
        websiteLink: "https://github.com/aviBandi/Deca-Lettering-Automation",
        codeLink: "https://github.com/aviBandi/Deca-Lettering-Automation"
    },

    {

        title: "Blackjack",
        desc: "Blackjack game anyone can play for fun or to sharpen their skills... also my first project in Java.",
        skills: ["Java", "User Friendliness", "Error Checking", "Game Audio"],
        imgSrc: blackjackPic,
        websiteLink: "https://github.com/aviBandi/Blackjack-in-java",
        codeLink: "https://github.com/aviBandi/Blackjack-in-java"
    },
    {
        title: "SheCan MN Website",
        desc: "State website that empowers women to explore STEM careers and fields.",
        skills: ["React.js", "TailwindCSS", "HTML", "Figma", "Vercel", "NPM"],
        imgSrc: shecanPic,
        websiteLink: "",
        codeLink: ""
    },
    {
        title: "Database Metadata Reporter",
        desc: "Uses ArcGIS Pro's arcypy library to send out Excel metadata reports on SQL databases.",
        skills: ["ArcGIS Pro", "ArcPy", "Python", "SMTPLIB ", "Pandas", "Metadata"],
        imgSrc: metaDataReportsSenderPic,
        websiteLink: "https://github.com/aviBandi/ArcGIS-Pro-Metadata-Reports",
        codeLink: "https://github.com/aviBandi/ArcGIS-Pro-Metadata-Reports"
    },



]

// On services page
const servicesList = [
    {
        title: "Company Website",
        description: "Custom company website with no hidden fees. Created by a world class designer, this website can scale your buisness to the next level.",
        cost: "500",
        whyMe: ["100% Code Ownership", "Personalized SEO", "Two Months Free Updates", "Satisfaction Gauranteed"]
    },
    {
        title: "Custom Code Script",
        description: "Custom script for your buisness tailored to your needs. Allows you to acheive new heights with technology to do the heavy lifting.",
        cost: "450",
        whyMe: ["100% Code Ownership", "Python or Java", "Efficient Clean Code", "Intuitive GUI"]
    }
]

const team = [
    {
        name: "Avi Bandi",
        role: "Founder and CEO",
        image: aviPic,
    },
    {
        name: "Prohitt Kumar",
        role: "Chief Marketing Officer",
        image: prohittPic,
    },
    {
        name: "Rahul Billakanti",
        role: "Chief Financial Officer",
        image: rahulPic,
    },
    {
        name: "Sarvajith Karun",
        role: "Web Developer",
        image: sarvajithPic,
    }
]


const products = [

    {
        id: 1,
        imageSrc: hoodiePicThree,
        data: {
            id: 1,
            name: 'Hoodie',
            price: 29.99,
            sale_price: 22.99,
            breadcrumbs: [
                { id: 1, name: 'All Merch', href: '/merch' },
            ],
            images: [
                {
                    src: hoodiePicOne,
                    alt: 'Two each of gray, white, and black shirts laying flat.',
                },
                {
                    src: hoodiePicTwo,
                    alt: 'Model wearing plain black basic tee.',
                },

                {
                    src: hoodiePicThree,
                    alt: 'Model wearing plain white basic tee.',
                },
            ],
            outOfStock: false,

            sizes: [

                { name: 'S', inStock: true, id:0 },
                { name: 'M', inStock: true, id:1 },
                { name: 'L', inStock: true, id:2 },
                { name: 'XL', inStock: true, id:3 },

            ],
            description:
                'Picture yourself in the custom-made Avi Bandi Designs Hoodie, with ultimate breathability and versatility to last throughout all seasons. Its loose fit will work well with any outfit and is a must-have for every wardrobe and every person.',
            highlights: [
                'Handmade for uniqueness',
                'Unisex fit',
                'Pre-washed & pre-shrunk',
                'Ultra-soft',
            ],
        }        
    },
    {
        id: 2,
        imageSrc: teePicOne,
        
        data: {
            id: 2,
            name: 'T-Shirt',
            price: 19.99,
            sale_price: 14.99,
            breadcrumbs: [
                { id: 1, name: 'All Merch', href: '/merch' },
            ],
            images: [
                {
                    src: teePicTwo,
                    alt: 'Two each of gray, white, and black shirts laying flat.',
                },
                {
                    src: teePicThree,
                    alt: 'Model wearing plain black basic tee.',
                },
                // {
                //     src: teePicThree,
                //     alt: 'Model wearing plain gray basic tee.',
                // },
                {
                    src: teePicOne,
                    alt: 'Model wearing plain white basic tee.',
                },
            ],
            outOfStock: false,
            sizes: [

                { name: 'S', inStock: true, id:0 },
                { name: 'M', inStock: true, id:1 },
                { name: 'L', inStock: true, id:2 },
                { name: 'XL', inStock: false, id:3 },
            ],

            description:
                'Proudly hand-crafted by Avi Bandi himself, the Avi Bandi Designs Tee Shirt is a wardrobe necessity for any outfit. Its relaxed fit perfectly balances relaxed and slim, allowing for a comfortable natural hang that looks good.',
            highlights: [
                'Handmade for uniqueness',
                'Pre-washed & pre-shrunk',
                'Ultra-soft',
                'Unisex fit',
            ],
        }        
    },
];





export {projectInfo, instagramLink, linkedinLink, githubLink, servicesList, testimonialInfo, products, team};